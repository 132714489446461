<template>
	<div id="seedEdit">
		<page-seed-edit :is-shop="0"></page-seed-edit>
	</div>
</template>

<script>
	import pageSeedEdit from '@/components/layout/land/page-seed-edit.vue'
	export default{
		components:{
			pageSeedEdit
		}
	}
</script>

<style>

</style>
